<template>
	<div class="page_box">
		<div class="top_box">
			<div class="top_title">搜索栏</div>
			<div class="top_flex">
				<div class="search_flex">
					<div class="texts">姓名</div>
					<div class="inputs_box">
						<!-- <input type="text" placeholder="请输入" v-model="forms.keyword"> -->
						<el-input clearable type="text" placeholder="请输入" v-model="forms.keyword" />
					</div>
				</div>
				<div class="search_flex">
					<div class="texts">部门</div>
					<div class="inputs_box">
					 
						<bumen_cascader ref="bumen_cascader" :multiple="false" :is_show_position="false"
							@confirm="confirm_bumen_cascader_value" />
					</div>
				</div>
				<div class="search_flex">
					<div class="btn_ends" @click="handle_search">搜索</div>
				</div>
			</div>

		</div>
		<div class="bom_box">
			<div class="bom_btn_flex">
				<div class="lefts">
					{{ page_title }}
				</div>
				<div class="rights">

					<div class="btns" @click="add_salary()">
						新增员工标准薪资
					</div>
					<div class="btns" @click="do_export()">
						批量导入
					</div>
				</div>
			</div>


			<div class="filter-box">

			</div>

			<div class="bom_box_list">
				<el-table :data="list" stripe style="width: 100%" header-cell-class-name="el_table_title"
					@selection-change="handle_list_change" v-loading="loading" height="570">

					<el-table-column fixed prop="cus_name" label="姓名" width="180"></el-table-column>
					<el-table-column prop="cus_depart_title" label="部门" width="180"></el-table-column>
					<el-table-column prop="cus_level6" label="岗位" width="180"></el-table-column>
					<el-table-column prop="dinge_sal" label="定额工资" width="180"></el-table-column>
					<el-table-column prop="jiesuan_sal" label="结算工资" width="180"></el-table-column>
					<el-table-column prop="chuanpiao_sal" label="传票工资" width="180"></el-table-column>
					<el-table-column prop="base_sal" label="基本工资" width="180"></el-table-column>
					<el-table-column prop="gangwei_sal" label="岗位工资" width="180"></el-table-column>
					<el-table-column prop="gangwei_bz" label="岗位补助" width="180"></el-table-column>
					<el-table-column prop="other_sal" label="其他固定工资" width="180"></el-table-column>
					<el-table-column prop="updated_at" label="更新时间" width="180"></el-table-column>
					<!-- 操作模块   -->
					<el-table-column fixed="right" label="操作" width="140">
						<template slot-scope="scope">
							<!-- 	<el-button type="text" size="small" class="btn_open" @click="handle_btn_open(scope.row)"
								:disabled="scope.row.status=='open'">编辑</el-button>
							<el-button type="text" size="small" class="btn_close" @click="handle_btn_colse(scope.row)"
								:disabled="scope.row.status=='close'">{{ $t('btn_close') }}</el-button> -->
							<el-button type="text" size="small" class="btn_modify" @click="dow_row_edit(scope.row)">编辑</el-button>

						</template>
					</el-table-column>
				</el-table>
				<div class="pages">
					<page :pageson='count' @row="row" @currentPage="currentPage"></page>
				</div>
			</div>
		</div>


		<!--  -->
		<addSalary ref="addSalary" @confirm="confirm_edit" />


		<up_files ref="up_files" @handle_up_end="confirm_upload_temp" />
	</div>
</template>
<script>
	import dayjs from 'dayjs'
	import page from "@/components/page/page.vue"; //分页  统一的
	import addSalary from "@/components/salary/addSalary.vue"; //新增员工标准工资

	export default {
		components: {
			page,
			addSalary,
		},
		data() {
			return {
				loading: false, //加载总
				page_title: '', //页面名称  来之路由
				check_list: [], //选中的
				//提交数据
				forms: {
					keyword: '',
					depart_id: '',
				},
				list: [ 
				],
				pages: {
					page: 1,
					limit: 10,
				},
				count: 0,
				times: [], //时间范围

 
			 
				bumen_position_info: {
					position: '',
					bumen_titles: '',
				},
 
			};
		},
		computed: {},
		watch: {},
		created() {
			this.page_title = this.$route.meta.title
			this.get_list()
			this.query_all_department()

		},
		mounted() {

		},
		methods: {
			//接受条数
			currentPage(page) {
				console.log(page)
				this.pages.page = page
				this.get_list()
			},
			// 接收条数
			row(row) {
				console.log(row)
				this.pages.limit = row
				this.get_list()
			},
			//选择
			handle_list_change(e) {
				console.log('多选', e)
				this.check_list = e
			},
			//搜素
			handle_search() {
				this.pages.page = 1
				this.get_list()
			},
			//表单回调 触发重新渲染数据
			handle_get_list() {
				this.get_list()
			},
			//统一的列表接口
			get_list() {
			 


				console.log(this.forms)
				this.loading = true
				this.$api("empSalarySet", {
					// mobile:this.mobile
					...this.forms,
					...this.pages,
				}, "post").then((res) => {
					this.loading = false
					if (res.code == 200) {
						this.count = res.data.count
						let list = res.data.list
						list.forEach(v => {
							let user = v.user || {}
							v.cus_name = user.name
							v.cus_depart_title = user.depart_title
							v.cus_level6 = user.level6
						})
						this.list = list
					} else {
						alertErr(res.msg)
					}
				});
			},
			//所有组织架构
			query_all_department() {
				this.$api("getCategoryAllList", {

				}, "get").then((res) => {
					if (res.code == 200) {
						let list = res.data;
						this.handle_cate_list(list)
					}
				});
			},
			//处理所有分类数据
			handle_cate_list(list) {
				console.log('处理部门数据')
				this.$log('获取分类分组 groupList', this.groupList)
				this.$log('获取分类选中 groupSelectList', this.groupSelectList)
				let cate_level_map = {
					// 1: [], 
					// 2: [], 
				};
				list.forEach(v => {
					let {
						level
					} = v;
					if (!cate_level_map[level]) {
						cate_level_map[level] = []
					}
					cate_level_map[level].push(v)
				})

				this.cate_flat_list = list;
				this.cate_level_map = cate_level_map;
				this.$log('cate_level_map', cate_level_map)
			},

			confirm_bumen_cascader_value(value, bumen_items, bumen_titles) {
				this.$log('勾选部门数据', value)
				let id_arr = value;
				let departIds = id_arr.join(',')
				let departItems = JSON.stringify(value)

				this.$log('departIds', departIds)
				this.$log('departItems', departItems)

				this.bumen_position_info = {
					position: departIds,
					bumen_titles: bumen_titles
				}

				this.$log('选中的数据', this.bumen_position_info)
				this.forms.depart_id=value[value.length-1]


				//岗位
				this.position_list = this.cate_flat_list.filter(v => v.parent_id == id_arr[id_arr.length - 1])

				this.$log('岗位列表 position_list', this.position_list)
			},


		 

		 
 
			//新增员工标准薪资
			add_salary() {
				this.$refs.addSalary.init()
			},

			//编辑
			dow_row_edit(row) {
				// this.$refs.shebao_user_add_modal.init(row)
				this.$refs.addSalary.init(row)
			},
			//导出
			do_export() {
				let obj={
					file_url:'http://jinniu.dx.hdapp.com.cn/dr_file/基本工资模板.xlsx',
				}
				this.$refs.up_files.handle_open(obj)

			},


			confirm_edit() {
				this.get_list()
			},
			confirm_upload_temp(info) {
				this.$api("socialImportXls", {
					file: info.path,
					type: 'salary'
				}, "post").then((res) => {
					alert(res)
					if (res.code == 200) {
						this.get_list()
					}
				});
			}

		},


	};
</script>

<style scoped lang="less">
	.filter-box {
		padding: 30px 20px;
		padding-bottom: 0;

		.filter-group {
			margin-bottom: 10px;
			display: flex;
			align-items: flex-start;

			.filter-label {
				min-width: 100px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				font-size: 14px;
				color: #333333;
			}

			.filter-items {
				flex: 1;
				overflow: hidden;

				.el-checkbox {
					margin-bottom: 20px;
				}
			}
		}
	}



	.page_box {
		height: 100%;

		// background-color: red;
		.top_box {
			width: 100%;
			// height: 80px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			padding-bottom: 29px;

			.top_title {
				padding: 10px 17px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: bold;
				font-size: 14px;
				color: #333333;
			}

			.top_flex {
				display: flex;
				align-items: center;
				// justify-content: space-between;
				flex-wrap: wrap;
				padding: 0 77px;

				.search_flex {
					// width: 25%;
					display: flex;
					align-items: center;
					padding-right: 48px;

					.texts {
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #333333;
						white-space: nowrap;
						padding-right: 12px;
					}

					.inputs_box {
						flex: 1;

						input {
							width: 236px;
							height: 40px;
							background: #FFFFFF;
							border-radius: 0px 0px 0px 0px;
							border: 1px solid #E8E8E8;
							padding: 0 9px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							font-size: 14px;
							color: #747474;

						}
					}


					.btn_ends {
						width: 80px;
						height: 40px;
						background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
						border-radius: 2px 2px 2px 2px;
						text-align: center;
						line-height: 40px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						cursor: pointer;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

						}
					}

				}
			}




		}

		.bom_box {
			background: #FFFFFF;

			.bom_btn_flex {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				height: 51px;
				background: #FFFFFF;
				box-shadow: 0px 3px 8px 1px rgba(0, 0, 0, 0.06);
				border-radius: 4px 4px 4px 4px;
				margin-top: 20px;
				padding: 0 20px;

				.lefts {
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: bold;
					font-size: 14px;
					color: #333333;
				}

				.rights {
					display: flex;
					align-items: center;

					.btns {
						margin-left: 20px;
						background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
						padding: 6px 13px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						cursor: pointer;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

						}
					}

				}
			}

			.bom_box_list {
				width: 1640px;
				height: 670px;
				padding: 20px 20px 32px 20px;

				.pages {
					padding-top: 30px;
				}
			}

		}
	}
</style>